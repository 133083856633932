/*************************Slider de la lista de post  del HOME***************************/
class ListProjectHome extends HTMLElement{
    constructor(){
        super();

        this.listProjects = this.querySelector('.list-projects');
        this.projects = this.listProjects.querySelectorAll('li .header');
    }


    connectedCallback(){
        
        this.projects.forEach( item => item.addEventListener('click', this.onClickProject.bind(this)));   
        
        tns({
            container: this.listProjects,
            disable: false,
            loop: false,
            mouseDrag: true,
            items: 1,
            controls: false,
            nav: true,
            navPosition: 'bottom',
            responsive: {
                769: {
                    disable: true,
                }
            },
        });
    }

    onClickProject(evnt){
        evnt.preventDefault();
        this.projects.forEach(item => item.closest('li').classList.remove('active')); 
        evnt.currentTarget.closest('li').classList.add('active');
    }

}
customElements.define('home-projects', ListProjectHome);